import React, { useEffect, useRef } from 'react';

import { Link } from 'gatsby';

function Avatar() {
  return (
    <Link to="/" aria-label="Home">
      <div
        style={{
          width: 64,
          height: 64,
          borderRadius: '50%',
          overflow: 'hidden',
        }}
        className="avatar-container"
      >
        <img
          src="/images/head.jpg"
          alt="Avatar"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
    </Link>
  );
}

function Header() {
  const headerRef = useRef(null);
  const avatarRef = useRef(null);

  useEffect(() => {}, []);

  return (
    <header
      className="mx-auto max-w-2xl lg:max-w-5xl px-4 md:px-0"
      ref={headerRef}
    >
      <div ref={avatarRef} className="mt-6">
        <Avatar />
      </div>
    </header>
  );
}

export default Header;
